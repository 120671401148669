<template>
  <div class="useRegistration">
    <van-nav-bar title="设备使用登记" left-text="返回" @click-left="back()" left-arrow style="z-index:1000">
      <van-icon name="scan" slot="right" class="tabIcon" @click="scanClick()" />
    </van-nav-bar>
    <div class="useRegistration-content">
      <van-cell-group>
        <van-field v-model="equipmentForm.code" label="设备编号" placeholder="请扫码" readonly clearable :label-width="100" />
        <van-field v-model="equipmentForm.name" label="设备名称" placeholder="请扫码" readonly clearable :label-width="100" />
        <van-field readonly v-model="equipmentForm.projectName" clickable type="textarea" autosize :label="'选择项目'"
          :placeholder="'请选择项目'" @click="showPopup('showPickerUserddp')" v-if="isItMe" />
        <van-field v-model="equipmentForm.startTime" label="开始使用时间" placeholder="请选择使用时间"
          @click="showPopup('endDateShow')" v-if="isItMe" readonly clickable></van-field>
        <van-field v-model="equipmentForm.endTime" label="结束使用时间" placeholder="请选择结束时间"
          @click="showPopup('endDateShowTwo', false)" v-if="status == 1 && isItMe" readonly clickable></van-field>
        <van-field v-model="durationOfUse" label="已使用时长(小时)" placeholder="请扫码" readonly clearable :label-width="120"
          v-if="status == 1 && isItMe" />
      </van-cell-group>
      <!-- 选择时间 -->
      <van-popup v-model="endDateShow" position="bottom">
        <van-datetime-picker type="datetime" title="选择开始时间" v-model="currentDate2" @confirm="endDateChange"
          @cancel="endDateShow = false; $forceUpdate();" />
      </van-popup>
      <van-popup v-model="endDateShowTwo" position="bottom">
        <van-datetime-picker type="datetime" title="选择结束时间" v-model="currentDatetwo2" @confirm="endDateChangeTwo"
          @cancel="endDateShowTwo = false; $forceUpdate();" />
      </van-popup>
      <!-- 不是本人 -->
      <template v-if="!isItMe">
        <van-field v-model="equipmentForm.usageUserName" label="使用人" readonly error clearable :label-width="100" />
        <div class="equipmentUsage">该设备当前已被占用，请换其他设备扫码</div>
      </template>
      <!-- 选择项目 -->
      <van-popup v-model="showPickerUserddp" position="bottom" style="height: 84%">
        <div class="popupDiv">
          <div class="popupSearch">
            <van-search v-model="userName" shape="round" background="#F4F4F4" placeholder="请输入项目名称/编号" @clear="sea()"
              @blur="sea()" @search="sea()" @input="sea()" />
          </div>

          <div class="popupCon">
            <div class="popupTitle">近期选择项目</div>

            <div v-for="(item, index) in integrationProjectList" :key="index" class="popupItem paddingDiv"
              @click="fZr(item, index)">
              <div class="popupItemOne" v-if="item.projectName">{{ item.projectName }}</div>
              <p class="popupItemTwo" v-if="item.projectCode">{{ item.projectCode }}</p>
            </div>

            <div class="popupTitle marginTop">全部项目</div>

            <div v-for="(item, index) in projectss" :key="item.id" class="popupItem paddingDiv"
              @click="fZr(item, index)">
              <div class="popupItemOne" v-if="item.projectName">{{ item.projectName }}</div>
              <p class="popupItemTwo" v-if="item.projectCode">{{ item.projectCode }}</p>
            </div>

            <!-- <div class="popupTitle paddingDiv">全部项目</div> -->
          </div>
        </div>
      </van-popup>
    </div>
    <div class="useRegistration-footer" v-if="isItMe">
      <van-button type="primary" size="info" round block @click="operation" :disabled="!this.equipmentForm.code">{{
        status == 0 ? '确定开始使用' : '确定结束使用' }}</van-button>
    </div>
    <!-- 扫码 -->
    <div class="qrcodeClass" v-if="showQrcode">
      <Qrcode @closeQrcode="closeQrcode" @determineQrcode="determineQrcode" />
    </div>
  </div>
</template>

<script>
import Qrcode from '../../components/qrcode.vue'
export default {
  components: { Qrcode },
  data() {
    return {
      equipmentForm: {
        code: '',
        name: '',
        projectName: '',
        startTime: this.getToday()
      },
      endDateShow: false,
      currentDate2: this.getToday(),
      showPickerUserddp: false,
      userName: '',
      integrationProjectList: [],
      projectss: [],
      project: [],
      proads: [],
      showQrcode: false,
      status: 0, // 0: 新增，1：编辑
      durationOfUse: 0,
      endDateShowTwo: false,
      currentDatetwo2: this.getToday(),
      userInfo: JSON.parse(localStorage.userInfo),
      isItMe: true // 是否为本人
    };
  },
  mounted() {
    this.reset()
    this.getRecentlyProject()
    this.getPeoject()
  },
  methods: {
    operation() {
      const { id, projectId, startTime, endTime, deviceLogId } = this.equipmentForm
      console.log(this.equipmentForm, '<===== this.equipmentForm')
      if (endTime) {
        const one = new Date(startTime)
        const two = new Date(endTime)
        if (one > two) {
          this.$toast.fail('结束时间不能早于开始时间')
          return
        }
      }
      this.$axios.post("/device-log/updateDeviceUsageStatus", {
        id,
        projectId,
        startTime,
        endTime,
        deviceLogId,
        startOrEnd: !this.status ? true : false,
      })
        .then(res => {
          if (res.code == "ok") {
            this.$toast.success('操作成功')
            this.resetData()
          } else {
            this.$toast.fail('获取失败:' + res.msg);
          }
        }).catch(err => { this.$toast.clear(); });
    },
    resetData() {
      this.equipmentForm = {
        code: '',
        name: '',
        projectName: '',
        startTime: this.getToday()
      }
      this.status = 0
    },
    closeQrcode() {
      this.showQrcode = false
    },
    scanClick() {
      this.showQrcode = true
      // setTimeout(() => {
      //   // const val = '{"id":3,"deviceCode":"设备编号1","deviceName":"设备名称1","deviceModel":"设备型号1","manufacturer":"制造商1","purchaseDate":"2024-10-09","maintenanceDate":"2024-10-10","companyId":10,"usageStatus":false,"usageUserId":null,"usageUserName":null,"projectId":null,"projectName":null,"startTime":null,"endTime":null,"startOrEnd":null,"deviceLogId":null,"useTime":null}'
      //   const val = localStorage.getItem('scanCodeData')
      //   this.determineQrcode(val)
      // }, 3000)
    },
    determineQrcode(val) {
      console.log(val, '<=========== val')
      if (val != '') {
        const newVal = JSON.parse(val)
        this.$axios.post("/device/getDeviceDetail", { id: newVal.id })
          .then(res => {
            if (res.code == "ok") {
              console.log(res, '<===== 返回的数据')
              const values = res.data
              const endtimes = this.getToday()
              this.equipmentForm = {
                ...values,
                code: values.deviceCode,
                name: values.deviceName,
                projectName: values.projectName,
                startTime: values.startTime || this.getToday(),
                id: values.id,
                endTime: endtimes
              }
              this.durationOfUse = this.calculateUsageTime(res.data)
              this.currentDatetwo2 = endtimes
              this.status = values.usageStatus ? 1 : 0
              if (values.usageUserId) {
                this.isItMe = values.usageUserId == this.userInfo.id
              } else {
                this.isItMe = true
              }
            } else {
              this.$toast.fail('获取失败:' + res.msg);
            }
          }).catch(err => { this.$toast.clear(); });
      } else {
        this.$toast.fail('无效的二维码')
      }
      this.closeQrcode()
    },
    showPopup(type, flag = true) {
      if (this.status == 1 && flag) {
        return
      }
      this[type] = true
    },
    calculateUsageTime(row) {
      const { startTime } = row
      if (!startTime) {
        return 0
      }
      const endTime = this.getToday()
      const startDate = new Date(startTime);
      const endDate = new Date(endTime);
      const diffInMs = endDate - startDate;
      const diffInHours = diffInMs / (1000 * 60 * 60);
      return diffInHours.toFixed(2);
    },
    reset() {
      this.equipmentForm = {
        code: '',
        name: '',
        projectName: '',
        startTime: this.getToday()
      }
    },
    fZr(row, index) {
      this.equipmentForm.projectName = row.projectName
      this.equipmentForm.projectId = row.id
      this.showPickerUserddp = false
    },
    sea() {
      if (this.userName.length > 0) {
        let data = this.proads.filter(item => { return item.projectName.indexOf(this.userName.toUpperCase()) != '-1' });
        let dataList = this.proads.filter(item => { return item.projectCode.indexOf(this.userName.toUpperCase()) != '-1' });
        let dataTree = data.concat(dataList)
        let arrList = Array.from(new Set(dataTree))
        this.projectss = arrList
      } else {
        this.projectss = this.proads
      }
    },
    getToday() {
      return this.formatDate(new Date())
    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // 获取月份，并确保两位数
      const day = String(date.getDate()).padStart(2, '0'); // 获取日期，并确保两位数
      const hours = String(date.getHours()).padStart(2, '0'); // 获取小时，并确保两位数
      const minutes = String(date.getMinutes()).padStart(2, '0'); // 获取分钟，并确保两位数

      return `${year}-${month}-${day} ${hours}:${minutes}`;
    },
    endDateChange(date) {
      console.log('执行了')
      this.equipmentForm.startTime = this.formatDate(date)
      this.endDateShow = false
    },
    endDateChangeTwo(date) {
      this.equipmentForm.endTime = this.formatDate(date)
      this.endDateShowTwo = false
    },
    back() {
      history.back();
    },
    getRecentlyProject() {
      this.$axios.post('/project/nearProject', {})
        .then(res => {
          if (res.code == 'ok') {
            this.integrationProjectList = res.data;
          }
        }).catch(err => { this.$toast.clear(); this.cardRefLoading = false; })
    },
    getPeoject() {
      this.$axios.post("/project/getProjectList", { forReport: 1 })
        .then(res => {
          if (res.code == "ok") {
            for (var i in res.data) {
              if (res.data[i].projectCode == 'null' || res.data[i].projectCode == null) {
                res.data[i].projectCode = ' '
              }
            }
            this.projectss = res.data;
            this.project = res.data;
            this.projectss = this.projectss.filter(p => p.status == 1 || p.status == 4);
            this.proads = res.data
          } else {
            this.$toast.fail('获取失败:' + res.msg);
          }
        }).catch(err => { this.$toast.clear(); });
    },
  },
};
</script>

<style lang="less" scoped>
.useRegistration {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: #fff;

  .useRegistration-content {
    flex: 1;
    overflow: auto;
  }

  .useRegistration-footer {
    width: 100%;
    padding: 20px 20px 20px 20px;
  }

  .tabIcon {
    font-size: 20px;
    font-weight: bold;
  }

  .qrcodeClass {
    position: absolute;
    width: 100%;
    height: 100vh;
    z-index: 9999;
  }

  .equipmentUsage {
    width: 100%;
    text-align: center;
    color: #ee0a24;
    padding-top: 40px;
  }
}
</style>